import { Region } from "@medusajs/medusa"
import { cache } from "react"
import { medusaClient, throwMedusaError } from "src/utilities/medusa"

const DEFAULT_COUNTRY_CODE = "de"

export const listRegions = cache(async () =>
  medusaClient.regions
    .list()
    .then(({ regions }) => regions)
    .catch((error) => {
      console.error(error)
      return [] as Region[]
    }),
)

export const listCountryCodes = cache(async () => {
  const regions = await listRegions()

  return regions.flatMap((region) => {
    return region.countries.map((country) => {
      return country.iso_2
    })
  })
})

export const retrieveRegion = cache(async (id: string) => {
  return medusaClient.regions
    .retrieve(id)
    .then(({ region }) => region)
    .catch(throwMedusaError)
})

const regionMap = new Map<string, Region>()

export const getRegion = cache(async (countryCode: string) => {
  if (regionMap.has(countryCode)) {
    return regionMap.get(countryCode)!
  }

  const regions = await listRegions()

  for (const region of regions) {
    for (const country of region.countries) {
      regionMap.set(country.iso_2, region)
    }
  }

  const region = countryCode ? regionMap.get(countryCode) : regionMap.get(DEFAULT_COUNTRY_CODE)

  if (!region) {
    throw new Error(`Region for country code ${countryCode} not found`)
  }

  return region
})
