import {
  StorePostCustomersCustomerAddressesAddressReq,
  StorePostCustomersCustomerAddressesReq,
  StorePostCustomersCustomerReq,
  StorePostCustomersReq,
} from "@medusajs/medusa"
import { cache } from "react"
import { medusaClient, throwMedusaError } from "src/utilities/medusa"

export async function createCustomer(data: StorePostCustomersReq) {
  return medusaClient.customers
    .create(data)
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}

export async function updateCustomer(data: Partial<StorePostCustomersCustomerReq>) {
  return medusaClient.customers
    .update(data)
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}

export async function addShippingAddress(data: StorePostCustomersCustomerAddressesReq) {
  return medusaClient.customers.addresses
    .addAddress(data)
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}

export async function deleteShippingAddress(addressId: string) {
  return medusaClient.customers.addresses
    .deleteAddress(addressId)
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}

export async function updateShippingAddress(addressId: string, data: StorePostCustomersCustomerAddressesAddressReq) {
  return medusaClient.customers.addresses
    .updateAddress(addressId, data)
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}

export const listCustomerOrders = cache(async (limit = 10, offset = 0) => {
  return medusaClient.customers
    .listOrders({ limit, offset })
    .then(({ orders }) => orders)
    .catch(throwMedusaError)
})
