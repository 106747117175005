import { Order } from "@medusajs/medusa"
import { getCountryCode } from "src/localization/client"
import { ClientCustomer } from "src/modules/(account)/account/state"
import { ClientCart } from "src/modules/(checkout)/cart/state"
import { formatPhoneNumberToE164 } from "src/utilities/phoneNumber"
import { getContactData, mergeContactData } from "./state"

export function mergeContactDataFromObjects({
  order,
  cart,
  customer,
}: {
  order?: Order | null
  cart?: ClientCart | null
  customer?: ClientCustomer | null
}) {
  const contactData = getContactData()

  const email = order?.email || cart?.email || customer?.email || contactData.email || null
  const address = order?.shipping_address.first_name
    ? order.shipping_address
    : cart?.shipping_address?.first_name
      ? cart.shipping_address
      : customer?.shipping_addresses?.[0]
        ? customer.shipping_addresses[0]
        : contactData.address?.first_name
          ? contactData.address
          : null
  const phone = address?.phone || customer?.phone || contactData.phoneNormalized || null
  const countryCode = getCountryCode()
  const phoneNormalized = formatPhoneNumberToE164(phone, countryCode) || phone

  mergeContactData({
    email,
    phoneNormalized,
    address: {
      first_name: address?.first_name || undefined,
      last_name: address?.last_name || undefined,
      phone: address?.phone || undefined,
      metadata: address?.metadata,
      company: address?.company || undefined,
      address_1: address?.address_1 || undefined,
      address_2: address?.address_2 || undefined,
      city: address?.city || undefined,
      country_code: address?.country_code || undefined,
      province: address?.province || undefined,
      postal_code: address?.postal_code || undefined,
    },
  })
}
