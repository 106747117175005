import { TrackingCart, TrackingLineItem, TrackingOrder, TrackingProduct } from "src/tracking/types"
import { roundPrice } from "src/utilities/price"
import { slugify } from "src/utilities/slugify"
import {
  GoogleAddPaymentInfoEvent,
  GoogleAddShippingInfoEvent,
  GoogleAddToCartEvent,
  GoogleBeginCheckoutEvent,
  GoogleCartItemData,
  GoogleItemData,
  GooglePurchaseEvent,
  GoogleRemoveFromCartEvent,
  GoogleViewCartEvent,
  GoogleViewItemEvent,
  GoogleViewPromotionItemData,
} from "./types"

export function dataLayerFormatId<T extends string | undefined>(id: T): T {
  if (id) {
    return slugify(id).replace(/-/g, "_") as T
  }
  return undefined as T
}

export function mapProductToGoogleItemData(product: TrackingProduct) {
  return {
    item_id: product.sku,
    item_name: product.name,
    item_variant: product.variantName,
    item_category: product.categories?.[0],
    item_category2: product.categories?.[1],
    item_category3: product.categories?.[2],
    item_category4: product.categories?.[3],
    item_category5: product.categories?.[4],
    price: product.netOfferPrice,
    discount: product.netDiscount,
  } satisfies GoogleItemData
}

export function mapProductToGoogleViewItemEvent(product: TrackingProduct) {
  return {
    currency: "EUR",
    value: product.netOfferPrice,
    items: [mapProductToGoogleItemData(product)],
  } satisfies GoogleViewItemEvent
}

export function mapProductToGoogleViewPromotionItemData(product: TrackingProduct) {
  const item = mapProductToGoogleItemData(product)

  return {
    ...item,
    promotion_id: product.promotionId,
  } satisfies GoogleViewPromotionItemData
}

function mapTrackingLineItemToGoogleCartItemData(trackingLineItem: TrackingLineItem) {
  return {
    item_id: trackingLineItem.sku,
    item_name: trackingLineItem.name,
    item_variant: trackingLineItem.variantName,
    item_category: trackingLineItem.categories?.[0],
    item_category2: trackingLineItem.categories?.[1],
    item_category3: trackingLineItem.categories?.[2],
    item_category4: trackingLineItem.categories?.[3],
    item_category5: trackingLineItem.categories?.[4],
    price: trackingLineItem.netOfferPrice,
    discount: trackingLineItem.netDiscount,
    coupon: undefined, // this is not preserved on cart line items
    quantity: trackingLineItem.quantity,
    index: trackingLineItem.index,
  } satisfies GoogleCartItemData
}

export function mapLineItemToGoogleAddToCartEvent(trackingLineItem: TrackingLineItem) {
  const googleCartItem = mapTrackingLineItemToGoogleCartItemData(trackingLineItem)

  return {
    value: roundPrice(trackingLineItem.netOfferPrice * trackingLineItem.quantity),
    currency: trackingLineItem.currencyCode,
    items: [googleCartItem],
  } satisfies GoogleAddToCartEvent
}

export function mapProductToGoogleRemoveFromCartEvent(trackingLineItem: TrackingLineItem) {
  return mapLineItemToGoogleAddToCartEvent(trackingLineItem) satisfies GoogleRemoveFromCartEvent
}

export function mapCartToGoogleViewCartEvent(cart: TrackingCart) {
  return {
    value: cart.netValue,
    currency: cart.currencyCode,
    items: cart.items.map(mapTrackingLineItemToGoogleCartItemData),
  } satisfies GoogleViewCartEvent
}

export function mapCartToGoogleBeginCheckoutEvent(cart: TrackingCart) {
  return {
    value: cart.netValue,
    currency: cart.currencyCode,
    coupon: cart.discountCode,
    items: cart.items.map(mapTrackingLineItemToGoogleCartItemData),
  } satisfies GoogleBeginCheckoutEvent
}

export function mapCartToGoogleAddShippingInfoEvent(cart: TrackingCart) {
  return {
    ...mapCartToGoogleBeginCheckoutEvent(cart),
    shipping_tier: cart.shippingMethod,
  } satisfies GoogleAddShippingInfoEvent
}

export function mapCartOrderToGoogleAddPaymentInfoEvent(cart: TrackingCart) {
  return {
    ...mapCartToGoogleBeginCheckoutEvent(cart),
    payment_type: cart.paymentMethod,
  } satisfies GoogleAddPaymentInfoEvent
}

export function mapOrderToGooglePurchaseEvent(order: TrackingOrder) {
  return {
    transaction_id: order.transactionId,
    value: order.netValue,
    shipping: order.netShipping,
    tax: order.totalTax,
    currency: order.currencyCode,
    coupon: order.discountCode,
    items: order.items.map(mapTrackingLineItemToGoogleCartItemData),
  } satisfies GooglePurchaseEvent
}
