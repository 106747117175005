import { Cart } from "@medusajs/medusa"
import { useEffect } from "react"
import { mergeContactDataFromObjects } from "src/integrations/lib/mergeState"
import { useCountryCode } from "src/localization/client"
import { getCustomer } from "src/modules/(account)/account/state"
import { getOrCreateCart } from "src/modules/(checkout)/cart/api"
import { action_deleteCartCookie } from "src/modules/(checkout)/order/actions"
import { localStoragePersist } from "src/utilities/localStoragePersist"
import { SetOptional } from "type-fest"
import { create as createStore } from "zustand"

export type CheckoutStep = "address" | "delivery" | "payment" | "review"

export type ClientCart = SetOptional<
  Omit<Cart, "afterLoad" | "beforeInsert" | "object" | "refundable_amount" | "refunded_total">,
  "billing_address" | "shipping_address" | "email" | "payment" | "payment_session"
>

type State = {
  initializing?: boolean
  cartId: string | null
  cart: ClientCart | null
}

const defaultState: State = {
  initializing: true,
  cartId: null,
  cart: null,
}

const store = createStore(
  localStoragePersist(() => defaultState, {
    name: "cart",
    partialize: (state) => ({
      cartId: state.cartId,
    }),
  }),
)

export function setCart(cart: ClientCart) {
  store.setState({ cart: cart, cartId: cart?.id, initializing: false })

  const customer = getCustomer()

  mergeContactDataFromObjects({ cart, customer })
}

let initializedCountryCode = ""

async function initializeCart(countryCode: string) {
  if (initializedCountryCode !== countryCode) {
    initializedCountryCode = countryCode

    const cartId = getCartId()
    const cart = await getOrCreateCart(cartId, countryCode)

    setCart(cart)
  }
}

export function getCartId() {
  return store.getState().cartId
}

export function getCart() {
  return store.getState().cart
}

export function requireCartId() {
  const cartId = getCartId()

  if (!cartId) {
    throw new Error("No cartId cookie found")
  }

  return cartId
}

export function useCart() {
  const countryCode = useCountryCode()

  useEffect(() => {
    initializeCart(countryCode)
  }, [countryCode])

  return store((state) => state.cart)
}

export async function resetCart(countryCode: string) {
  store.setState({ cart: undefined, cartId: undefined })
  await action_deleteCartCookie()
  initializedCountryCode = ""
  await initializeCart(countryCode)
}
