import { Region } from "@medusajs/medusa"
import { cache } from "react"
import { DETAIL_PRODUCT_RELATIONS } from "src/data/medusa/config"
import { medusaClient } from "src/utilities/medusa"

export const getProductsByIds = cache(async ({ ids, regionId }: { ids: string[]; regionId: string }) => {
  return medusaClient.products
    .list({ id: ids, region_id: regionId })
    .then(({ products }) => products)
    .catch((error) => {
      console.error(error)
      return []
    })
})

export const retrievePricedProductById = cache(async ({ id, regionId }: { id: string; regionId: string }) => {
  return medusaClient.products
    .retrieve(`${id}?region_id=${regionId}&expand=${DETAIL_PRODUCT_RELATIONS}`)
    .then(({ product }) => product)
    .catch((error) => {
      console.error(error)
      return null
    })
})

export const getProductByHandle = cache(async (handle: string) => {
  const result = await medusaClient.products.list({ handle })

  return result.products[0]
})

export type GetProductListParams = {
  region: Region
  collection_id?: string[]
  category_id?: string[]
  tags?: string[]
  is_giftcard?: boolean
  limit?: number
  page: number
  expand?: string
}

export const getProductList = cache(
  async ({
    region,
    collection_id,
    category_id,
    tags,
    is_giftcard,
    limit = 12,
    page = 1,
    expand,
  }: GetProductListParams) => {
    const offset = (page - 1) * limit

    return medusaClient.products.list({
      collection_id,
      category_id,
      tags,
      is_giftcard,
      offset,
      limit,
      region_id: region.id,
      currency_code: region.currency_code,
      expand,
    })
  },
)
