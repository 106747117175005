import Medusa from "@medusajs/medusa-js"
import { AxiosError } from "axios"

// Defaults to standard port for Medusa server
const backendUrl = process.env.NEXT_PUBLIC_MEDUSA_BACKEND_ORIGIN
const apiKey = process.env.NEXT_PUBLIC_MEDUSA_API_KEY

if (!backendUrl) {
  throw new Error("Please set NEXT_PUBLIC_MEDUSA_BACKEND_ORIGIN")
}
if (!apiKey) {
  throw new Error("Please set NEXT_PUBLIC_MEDUSA_API_KEY")
}

export const medusaClient = new Medusa({
  publishableApiKey: apiKey,
  baseUrl: backendUrl,
  maxRetries: 3,
})

export function throwMedusaError(error: AxiosError): never {
  if (error.response) {
    const message =
      "Request failed: " +
      JSON.stringify(
        {
          method: error.config?.method,
          url: error.config?.url,
          requestData: error.config?.data,
          requestHeaders: error.config?.headers,
          status: error.response.status,
          responseData: error.response.data,
        },
        null,
        2,
      )

    throw new Error(message)
  }

  if (error.request) {
    const message =
      "Request failed without response: " +
      JSON.stringify(
        {
          method: error.config?.method,
          url: error.config?.url,
          data: error.config?.data,
          headers: error.config?.headers,
        },
        null,
        2,
      )

    throw new Error(message)
  }

  // Something happened in setting up the request that triggered an Error
  throw new Error("Error setting up the request: " + error.message)
}
