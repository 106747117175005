import { LineItem } from "@medusajs/medusa"
import { omit } from "lodash"
import { getProductsByIds } from "src/data/medusa/products"

export async function enrichLineItems(
  lineItems: LineItem[],
  regionId: string,
): Promise<Omit<LineItem, "beforeInsert" | "beforeUpdate" | "afterUpdateOrLoad">[] | undefined> {
  // Prepare query parameters
  const queryParams = {
    ids: lineItems.map((lineItem) => lineItem.variant.product_id),
    regionId: regionId,
  }

  // Fetch products by their IDs
  const products = await getProductsByIds(queryParams)

  // If there are no line items or products, return an empty array
  if (!lineItems?.length || !products) {
    return []
  }

  // Enrich line items with product and variant information

  const enrichedItems = lineItems.map((item) => {
    const product = products.find((product) => product.id === item.variant.product_id)
    const variant = product?.variants.find((variant) => variant.id === item.variant_id)

    if (!product || !variant) {
      // If product or variant is not found, return the original item
      return item
    }

    // If product and variant are found, enrich the item
    return {
      ...item,
      variant: {
        ...variant,
        product: omit(product, "variants"),
      },
    }
  }) as LineItem[]

  return enrichedItems
}
