import { StorePostAuthReq } from "@medusajs/medusa"
import { medusaClient, throwMedusaError } from "src/utilities/medusa"

export async function authenticate(credentials: StorePostAuthReq) {
  return medusaClient.auth
    .authenticate(credentials)
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}

export async function getSession() {
  return medusaClient.auth
    .getSession()
    .then(({ customer }) => customer)
    .catch(throwMedusaError)
}

medusaClient.customers.retrieve
